"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = __importStar(require("styled-components"));
var constant_1 = require("../../constant");
exports.blackboardAnimation = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    transform: translate(100vw);\n  }\n  100% {\n    transform: translate(0);\n  }\n"], ["\n  0% {\n    transform: translate(100vw);\n  }\n  100% {\n    transform: translate(0);\n  }\n"])));
exports.BlackBoard = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  background-color: #16160a;\n  margin-bottom: 20px;\n  border: 40px solid #f3a433;\n  border-radius: 10px;\n  position: relative;\n  animation: ", " 0.4s ease;\n\n  ", " {\n    border-left-width: 0;\n    border-right-width: 0;\n    border-radius: 0;\n    margin-top: 80px;\n  }\n\n  @media screen and (max-width: 830px) {\n    overflow: auto;\n  }\n"], ["\n  position: relative;\n  background-color: #16160a;\n  margin-bottom: 20px;\n  border: 40px solid #f3a433;\n  border-radius: 10px;\n  position: relative;\n  animation: ", " 0.4s ease;\n\n  ", " {\n    border-left-width: 0;\n    border-right-width: 0;\n    border-radius: 0;\n    margin-top: 80px;\n  }\n\n  @media screen and (max-width: 830px) {\n    overflow: auto;\n  }\n"])), exports.blackboardAnimation, constant_1.MEDIA.MAX.s);
exports.Pin = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 50px;\n  width: 50px;\n  z-index: 3;\n  position: absolute;\n  top: -40px;\n  left: calc(30% - 62px);\n  background: radial-gradient(\n      30px 30px at 15px 15px,\n      #e43d34 38%,\n      transparent 40%\n    ),\n    radial-gradient(20px 20px at 28px 20px, #e43d34 38%, transparent 40%),\n    linear-gradient(25deg, transparent 42%, grey 45%, grey 55%, transparent 58%)\n      no-repeat 30px 18px / 15px 15px;\n\n  ", " {\n    display: none;\n  }\n"], ["\n  height: 50px;\n  width: 50px;\n  z-index: 3;\n  position: absolute;\n  top: -40px;\n  left: calc(30% - 62px);\n  background: radial-gradient(\n      30px 30px at 15px 15px,\n      #e43d34 38%,\n      transparent 40%\n    ),\n    radial-gradient(20px 20px at 28px 20px, #e43d34 38%, transparent 40%),\n    linear-gradient(25deg, transparent 42%, grey 45%, grey 55%, transparent 58%)\n      no-repeat 30px 18px / 15px 15px;\n\n  ", " {\n    display: none;\n  }\n"])), constant_1.MEDIA.MAX.xs);
exports.ExperienceStyled = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding-bottom: 60px;\n  position: relative;\n\n  ", " {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding-bottom: 60px;\n  position: relative;\n\n  ", " {\n    flex-direction: column;\n  }\n"])), constant_1.MEDIA.MAX.xs);
exports.ExperienceTitle = styled_components_1.default.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #82bae0;\n  font-size: 22px;\n  line-height: 36px;\n  text-transform: uppercase;\n  font-weight: 800;\n  text-align: center;\n  width: 45%;\n  margin: 0 140px;\n  background-color: #fff;\n  padding: 40px 30px;\n  transition: 0.2s all ease;\n  transform-origin: top;\n  transform: rotate(", "deg);\n  position: relative;\n  z-index: 1;\n\n  ", " {\n    font-size: 18px;\n    margin: 40px;\n    padding: 20px;\n  }\n\n  ", " {\n    width: 70%;\n  }\n"], ["\n  color: #82bae0;\n  font-size: 22px;\n  line-height: 36px;\n  text-transform: uppercase;\n  font-weight: 800;\n  text-align: center;\n  width: 45%;\n  margin: 0 140px;\n  background-color: #fff;\n  padding: 40px 30px;\n  transition: 0.2s all ease;\n  transform-origin: top;\n  transform: rotate(", "deg);\n  position: relative;\n  z-index: 1;\n\n  ", " {\n    font-size: 18px;\n    margin: 40px;\n    padding: 20px;\n  }\n\n  ", " {\n    width: 70%;\n  }\n"])), function (_a) {
    var rotate = _a.rotate;
    return rotate;
}, constant_1.MEDIA.MAX.s, constant_1.MEDIA.MAX.xs);
exports.ExperienceResume = styled_components_1.default.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #fff;\n  font-size: 26px;\n  font-weight: 100;\n  width: 50%;\n  margin: 20px 40px 0 40px;\n  padding: 20px;\n  font-family: \"Vibur\", cursive;\n\n  ", " {\n    font-size: 22px;\n    padding: 0;\n  }\n\n  ", " {\n    width: 70%;\n    margin-top: 0;\n  }\n"], ["\n  color: #fff;\n  font-size: 26px;\n  font-weight: 100;\n  width: 50%;\n  margin: 20px 40px 0 40px;\n  padding: 20px;\n  font-family: \"Vibur\", cursive;\n\n  ", " {\n    font-size: 22px;\n    padding: 0;\n  }\n\n  ", " {\n    width: 70%;\n    margin-top: 0;\n  }\n"])), constant_1.MEDIA.MAX.s, constant_1.MEDIA.MAX.xs);
exports.HorizontalBorder = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 6px;\n  height: 110%;\n  background: linear-gradient(45deg, #e87f79 28%, transparent 28%) 10px 10px /\n      10px 10px,\n    linear-gradient(45deg, transparent 50%, #e87f79 50%) 10px 10px / 10px 10px,\n    #dc119d;\n  position: absolute;\n  top: -25px;\n  bottom: 0;\n  left: calc(30% - 20px);\n\n  ", " {\n    height: 6px;\n    width: 100%;\n    left: 0;\n    top: 36px;\n  }\n"], ["\n  width: 6px;\n  height: 110%;\n  background: linear-gradient(45deg, #e87f79 28%, transparent 28%) 10px 10px /\n      10px 10px,\n    linear-gradient(45deg, transparent 50%, #e87f79 50%) 10px 10px / 10px 10px,\n    #dc119d;\n  position: absolute;\n  top: -25px;\n  bottom: 0;\n  left: calc(30% - 20px);\n\n  ", " {\n    height: 6px;\n    width: 100%;\n    left: 0;\n    top: 36px;\n  }\n"])), constant_1.MEDIA.MAX.xs);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
