"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = __importStar(require("styled-components"));
var Layout_1 = require("../../components/Layout");
var constant_1 = require("../../constant");
exports.PictureTitle = styled_components_1.default.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 22px;\n  font-weight: bold;\n  line-height: 24px;\n  text-transform: uppercase;\n  color: #fff;\n  transition: 0.3s all ease;\n  margin-bottom: 20px;\n  transform: translateY(200px);\n\n  ", " {\n    font-size: 18px;\n    margin-bottom: 10px;\n  }\n"], ["\n  font-size: 22px;\n  font-weight: bold;\n  line-height: 24px;\n  text-transform: uppercase;\n  color: #fff;\n  transition: 0.3s all ease;\n  margin-bottom: 20px;\n  transform: translateY(200px);\n\n  ", " {\n    font-size: 18px;\n    margin-bottom: 10px;\n  }\n"])), constant_1.MEDIA.MAX.s);
exports.PictureSubTite = styled_components_1.default.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 18px;\n  color: #fff;\n  transition: 0.4s all ease;\n  line-height: 22px;\n  transform: translateY(200px);\n\n  ", " {\n    font-size: 16px;\n    line-height: 16px;\n  }\n"], ["\n  font-size: 18px;\n  color: #fff;\n  transition: 0.4s all ease;\n  line-height: 22px;\n  transform: translateY(200px);\n\n  ", " {\n    font-size: 16px;\n    line-height: 16px;\n  }\n"])), constant_1.MEDIA.MAX.s);
exports.Picture = styled_components_1.default.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  transition: 0.3s all ease !important;\n\n  ", " {\n    width: 615px;\n    height: 385px;\n  }\n\n  ", " {\n    width: 100%;\n    height: 228px;\n  }\n"], ["\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  transition: 0.3s all ease !important;\n\n  ", " {\n    width: 615px;\n    height: 385px;\n  }\n\n  ", " {\n    width: 100%;\n    height: 228px;\n  }\n"])), constant_1.MEDIA.MAX.s, constant_1.MEDIA.MAX.xs);
exports.Link = styled_components_1.default.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-decoration: none;\n  position: relative;\n  line-height: 0;\n  overflow: hidden;\n  margin-bottom: 10px;\n\n  &:hover ", " {\n    opacity: 1;\n  }\n\n  &:hover ", " {\n    transform: scale(1.1);\n  }\n\n  &:hover ", ", &:hover ", " {\n    transform: translateY(0);\n  }\n"], ["\n  text-decoration: none;\n  position: relative;\n  line-height: 0;\n  overflow: hidden;\n  margin-bottom: 10px;\n\n  &:hover ", " {\n    opacity: 1;\n  }\n\n  &:hover ", " {\n    transform: scale(1.1);\n  }\n\n  &:hover ", ", &:hover ", " {\n    transform: translateY(0);\n  }\n"])), Layout_1.Overlay, exports.Picture, exports.PictureTitle, exports.PictureSubTite);
exports.computerAnimation = styled_components_1.keyframes(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  0% {\n    transform: translate(100%);\n  }\n  100% {\n    transform: translate(0);\n  }\n"], ["\n  0% {\n    transform: translate(100%);\n  }\n  100% {\n    transform: translate(0);\n  }\n"])));
exports.Computer = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  height: calc(100vh - 130px);\n  animation: ", " ease 0.4s;\n  position: relative;\n\n  ", " {\n    height: calc(100vh - 74px);\n    margin-top: 80px;\n  }\n"], ["\n  display: flex;\n  height: calc(100vh - 130px);\n  animation: ", " ease 0.4s;\n  position: relative;\n\n  ", " {\n    height: calc(100vh - 74px);\n    margin-top: 80px;\n  }\n"])), exports.computerAnimation, constant_1.MEDIA.MAX.s);
exports.Screen = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: 15px solid black;\n  border-right-width: 15px;\n  border-left-width: 15px;\n  border-top-width: 40px;\n  border-bottom: none;\n  border-radius: 20px 20px 0 0;\n  overflow: hidden;\n  box-shadow: 0 0 0 6px #d9d9d9;\n  position: relative;\n  width: 100%;\n  background-color: #f4f4f4;\n\n  ", " {\n    border-right-width: 0;\n    border-left-width: 0;\n    border-radius: 0;\n  }\n"], ["\n  border: 15px solid black;\n  border-right-width: 15px;\n  border-left-width: 15px;\n  border-top-width: 40px;\n  border-bottom: none;\n  border-radius: 20px 20px 0 0;\n  overflow: hidden;\n  box-shadow: 0 0 0 6px #d9d9d9;\n  position: relative;\n  width: 100%;\n  background-color: #f4f4f4;\n\n  ", " {\n    border-right-width: 0;\n    border-left-width: 0;\n    border-radius: 0;\n  }\n"])), constant_1.MEDIA.MAX.s);
exports.Camera = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 20px;\n  width: 20px;\n  border-radius: 50%;\n  background-color: #333;\n  position: absolute;\n  top: 10px;\n  left: 50%;\n  z-index: 1;\n  overflow: hidden;\n\n  &:before {\n    content: \"\";\n    display: block;\n    box-sizing: border-box;\n    height: 16px;\n    width: 16px;\n    border-radius: 50%;\n    background: linear-gradient(30deg, rgba(255, 255, 255, 0.4) 25%, #000 25%);\n    border: 2px solid #111;\n    z-index: 2;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 20px;\n  width: 20px;\n  border-radius: 50%;\n  background-color: #333;\n  position: absolute;\n  top: 10px;\n  left: 50%;\n  z-index: 1;\n  overflow: hidden;\n\n  &:before {\n    content: \"\";\n    display: block;\n    box-sizing: border-box;\n    height: 16px;\n    width: 16px;\n    border-radius: 50%;\n    background: linear-gradient(30deg, rgba(255, 255, 255, 0.4) 25%, #000 25%);\n    border: 2px solid #111;\n    z-index: 2;\n  }\n"])));
exports.Window = styled_components_1.default.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  overflow: hidden;\n  border-radius: 4px 0 0 0;\n  position: absolute;\n  top: 10px;\n  bottom: 0;\n  right: 0;\n  left: 10px;\n  background-color: #fff;\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);\n  transition: 0.5s all ease;\n  transform-origin: right bottom;\n  transform: scale(0);\n\n  ", "\n"], ["\n  overflow: hidden;\n  border-radius: 4px 0 0 0;\n  position: absolute;\n  top: 10px;\n  bottom: 0;\n  right: 0;\n  left: 10px;\n  background-color: #fff;\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);\n  transition: 0.5s all ease;\n  transform-origin: right bottom;\n  transform: scale(0);\n\n  ",
    "\n"])), function (_a) {
    var showWindow = _a.showWindow;
    return showWindow && styled_components_1.css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      transform: scale(1);\n    "], ["\n      transform: scale(1);\n    "])));
});
exports.TopBar = styled_components_1.default.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  height: 20px;\n  width: 100%;\n  background: rgba(230, 234, 237);\n  display: flex;\n  padding-left: 5px;\n"], ["\n  height: 20px;\n  width: 100%;\n  background: rgba(230, 234, 237);\n  display: flex;\n  padding-left: 5px;\n"])));
exports.Button = styled_components_1.default.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  height: 8px;\n  width: 8px;\n  border-radius: 50%;\n  margin-top: 6px;\n  margin-left: 5px;\n  cursor: pointer;\n"], ["\n  height: 8px;\n  width: 8px;\n  border-radius: 50%;\n  margin-top: 6px;\n  margin-left: 5px;\n  cursor: pointer;\n"])));
exports.RedButton = styled_components_1.default(exports.Button)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background-color: rgb(255, 55, 55);\n"], ["\n  background-color: rgb(255, 55, 55);\n"])));
exports.YellowButton = styled_components_1.default(exports.Button)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  background-color: rgb(255, 193, 0);\n"], ["\n  background-color: rgb(255, 193, 0);\n"])));
exports.GreenButton = styled_components_1.default(exports.Button)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  background-color: rgb(0, 208, 60);\n"], ["\n  background-color: rgb(0, 208, 60);\n"])));
exports.Scrollable = styled_components_1.default.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  position: absolute;\n  top: 20px;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  overflow: auto;\n  padding: 10px;\n"], ["\n  position: absolute;\n  top: 20px;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  overflow: auto;\n  padding: 10px;\n"])));
exports.ProjectContainer = styled_components_1.default.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n\n  ", " {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n\n  ", " {\n    flex-direction: column;\n  }\n"])), constant_1.MEDIA.MAX.s);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
