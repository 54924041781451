"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = __importStar(require("styled-components"));
var constant_1 = require("../../constant");
exports.BurgerMenuContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: none;\n  position: relative;\n  justify-content: flex-end;\n  padding: 20px;\n  height: 90px;\n  z-index: 101;\n\n  ", " {\n    display: flex;\n    height: 54px;\n    padding: 12px;\n    width: 50px;\n    position: absolute;\n    top: 0;\n    right: 0;\n  }\n"], ["\n  display: none;\n  position: relative;\n  justify-content: flex-end;\n  padding: 20px;\n  height: 90px;\n  z-index: 101;\n\n  ", " {\n    display: flex;\n    height: 54px;\n    padding: 12px;\n    width: 50px;\n    position: absolute;\n    top: 0;\n    right: 0;\n  }\n"])), constant_1.MEDIA.MAX.s);
exports.MenuButton = styled_components_1.default.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: transparent;\n  border: transparent;\n  outline: none;\n  cursor: pointer;\n  padding: 10px;\n"], ["\n  background: transparent;\n  border: transparent;\n  outline: none;\n  cursor: pointer;\n  padding: 10px;\n"])));
exports.overlayAnimation = styled_components_1.keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"], ["\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"])));
exports.OverlayMenu = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: rgba(188, 228, 255, 1);\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  z-index: 100;\n  animation: ", " ease 0.4s;\n"], ["\n  background: rgba(188, 228, 255, 1);\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  z-index: 100;\n  animation: ", " ease 0.4s;\n"])), exports.overlayAnimation);
exports.LogoBurgerMenu = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 4px;\n  width: 40px;\n  background-color: ", ";\n  position: relative;\n  margin: 14px 0;\n  transition: 0.3s all ease;\n\n  &:before {\n    content: \"\";\n    display: block;\n    height: 4px;\n    background-color: #fff;\n    position: absolute;\n    top: -14px;\n    right: 0;\n    left: 0;\n    transition: 0.3s all ease;\n    transform-origin: left;\n\n    ", "\n  }\n\n  &:after {\n    content: \"\";\n    display: block;\n    height: 4px;\n    background-color: #fff;\n    position: absolute;\n    top: 14px;\n    right: 0;\n    left: 0;\n    transition: 0.3s all ease;\n    transform-origin: left;\n\n    ", "\n  }\n"], ["\n  height: 4px;\n  width: 40px;\n  background-color: ", ";\n  position: relative;\n  margin: 14px 0;\n  transition: 0.3s all ease;\n\n  &:before {\n    content: \"\";\n    display: block;\n    height: 4px;\n    background-color: #fff;\n    position: absolute;\n    top: -14px;\n    right: 0;\n    left: 0;\n    transition: 0.3s all ease;\n    transform-origin: left;\n\n    ",
    "\n  }\n\n  &:after {\n    content: \"\";\n    display: block;\n    height: 4px;\n    background-color: #fff;\n    position: absolute;\n    top: 14px;\n    right: 0;\n    left: 0;\n    transition: 0.3s all ease;\n    transform-origin: left;\n\n    ",
    "\n  }\n"])), function (_a) {
    var show = _a.show;
    return (show ? "transparent" : "#fff");
}, function (_a) {
    var show = _a.show;
    return show && styled_components_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        transform: rotate(45deg);\n      "], ["\n        transform: rotate(45deg);\n      "])));
}, function (_a) {
    var show = _a.show;
    return show && styled_components_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        transform: rotate(-45deg);\n      "], ["\n        transform: rotate(-45deg);\n      "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
