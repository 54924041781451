"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = __importStar(require("styled-components"));
var constant_1 = require("../../constant");
exports.deskAnimation = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    transform: translate(100%);\n  }\n  100% {\n    transform: translate(0);\n  }\n"], ["\n  0% {\n    transform: translate(100%);\n  }\n  100% {\n    transform: translate(0);\n  }\n"])));
exports.SkillsContainer = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  background-color: #d08a0c;\n  width: 100%;\n  height: calc(100vh - 130px);\n  animation: ", " ease 0.4s;\n  position: relative;\n  overflow: auto;\n\n  ", " {\n    height: calc(100vh - 20px);\n    padding: 12px;\n  }\n\n  ", " {\n    height: calc(100vh - 20px);\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  background-color: #d08a0c;\n  width: 100%;\n  height: calc(100vh - 130px);\n  animation: ", " ease 0.4s;\n  position: relative;\n  overflow: auto;\n\n  ", " {\n    height: calc(100vh - 20px);\n    padding: 12px;\n  }\n\n  ", " {\n    height: calc(100vh - 20px);\n  }\n"])), exports.deskAnimation, constant_1.MEDIA.MAX.s, constant_1.MEDIA.MAX.xs);
var bodyPen = "linear-gradient(transparent 75%, #d9d9d9 75%,#d9d9d9 97%, blue 97%),\nlinear-gradient(90deg, #d9d9d9 30%, black 32%,black 60%, #d9d9d9 62%)\n\n";
exports.Pen = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 400px;\n  width: 35px;\n  background: ", ";\n  position: absolute;\n  top: 20%;\n  right: 10%;\n  transform: translate(100vw);\n  transition: 0.3s all ease;\n  z-index: 1;\n\n  ", " {\n    display: none;\n  }\n\n  ", "\n\n  &:before {\n    content: \"\";\n    display: block;\n    height: 150px;\n    background-color: blue;\n    border-radius: 100% 100% 0 0;\n    position: absolute;\n    top: -100px;\n    left: -10px;\n    right: -5px;\n  }\n\n  &:after {\n    content: \"\";\n    display: block;\n    height: 100px;\n    width: 10px;\n    background-color: blue;\n    position: absolute;\n    top: 40px;\n    left: -10px;\n  }\n"], ["\n  height: 400px;\n  width: 35px;\n  background: ", ";\n  position: absolute;\n  top: 20%;\n  right: 10%;\n  transform: translate(100vw);\n  transition: 0.3s all ease;\n  z-index: 1;\n\n  ", " {\n    display: none;\n  }\n\n  ",
    "\n\n  &:before {\n    content: \"\";\n    display: block;\n    height: 150px;\n    background-color: blue;\n    border-radius: 100% 100% 0 0;\n    position: absolute;\n    top: -100px;\n    left: -10px;\n    right: -5px;\n  }\n\n  &:after {\n    content: \"\";\n    display: block;\n    height: 100px;\n    width: 10px;\n    background-color: blue;\n    position: absolute;\n    top: 40px;\n    left: -10px;\n  }\n"])), bodyPen, constant_1.MEDIA.MAX.s, function (_a) {
    var show = _a.show;
    return show && styled_components_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      transform: translate(0) rotate(-80deg);\n    "], ["\n      transform: translate(0) rotate(-80deg);\n    "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
