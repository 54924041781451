"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MEDIA = {
    MAX: {
        xxs: "@media screen and (max-width: 320px)",
        xs: "@media screen and (max-width: 600px)",
        s: "@media screen and (max-width: 768px)",
        m: "@media screen and (max-width: 1024px)",
        l: "@media screen and (max-width: 1600px)",
        xl: "@media screen and (max-width: 1980px)",
        xxl: "@media screen and (max-width: 2560px)",
    },
    MIN: {
        xxs: "@media screen and (min-width: 320px)",
        xs: "@media screen and (min-width: 600px)",
        s: "@media screen and (min-width: 768px)",
        m: "@media screen and (min-width: 1024px)",
        l: "@media screen and (min-width: 1600px)",
        xl: "@media screen and (min-width: 1980px)",
        xxl: "@media screen and (min-width: 2560px)",
    },
};
exports.SITE_TITLE = "Gloria Luzio, développeuse Javascript React - Paris";
